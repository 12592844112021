import React, { useState } from 'react';
import './Connection.css';
import Alert from '../Alert/Alert';
import { useAuth } from '../Services/AuthContext';
import { useNavigate } from 'react-router-dom';

function Connection() {
    const { login } = useAuth();
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [alertMessage, setAlertMessage] = useState({ message: '', type: '' });

    const validateInput = (input) => {
        const regex = /[`~<>;:"'/[\]|{}()=+]/;
        return !regex.test(input);
    };

    const handleLogin = () => {
        if (!validateInput(username) || !validateInput(password)) {
            setAlertMessage({ message: 'Le champ contient des caractères invalides.', type: 'error' });
            return;
        }

        fetch('https://test.logixmedia.ch/apprentihub/src/ApprentiHub/Backend/Connection.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username, password }),
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                login(parseInt(data.userId), data.permission); 
                navigate('/accueil'); 
            } else {
                setAlertMessage({ message: data.message, type: 'error' });
            }
        })
        .catch(error => {
            setAlertMessage({ message: 'Erreur de connexion.', type: 'error' });
        });
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleLogin();
        }
    };

    return (
        <div className='background-connection'>
            {alertMessage.message && (  
                <Alert message={alertMessage.message} type={alertMessage.type} onClose={() => setAlertMessage({ message: '', type: '' })} />
            )}
            <div className='container-connection'>
                <h1>Connexion</h1>
                <p>Veuillez remplir les champs ci-dessous.</p>
                <div className='input-box'>
                    <label htmlFor="username">Nom d'utilisateur</label>
                    <input
                        type="text"
                        name="username"
                        id="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        onKeyDown={handleKeyDown} 
                    />
                </div>
                <div className='input-box'>
                    <label htmlFor="password">Mot de passe</label>
                    <input
                        type="password"
                        name="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        onKeyDown={handleKeyDown} 
                    />
                </div>
                <div className='container-btn'>
                    <button onClick={handleLogin}>Connexion</button>
                </div>
            </div>
        </div>
    );
}

export default Connection;
