import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash, faUser, faUserGroup, faChevronLeft, faChevronRight, faMagnifyingGlass, faClockRotateLeft, faCheck } from '@fortawesome/free-solid-svg-icons';
import DialogTasks from './DialogTasks/DialogTasks';  
import './Tasks.css';
import { useAuth } from '../Services/AuthContext';

function Tasks() {
    const [tasks, setTasks] = useState([]);
    const [users, setUsers] = useState([]); 
    const [currentUserIndex, setCurrentUserIndex] = useState(0);
    const [selectedTasks, setSelectedTasks] = useState([]); 
    const [selectedTask, setSelectedTask] = useState(null);  
    const [isDialogOpen, setIsDialogOpen] = useState(false); 
    const [isNewTask, setIsNewTask] = useState(false);  
    const [statusFilter, setStatusFilter] = useState('inProgress'); 
    const { userId, permission } = useAuth();

    const fetchTasks = (id) => {
        fetch(`https://test.logixmedia.ch/apprentihub/src/ApprentiHub/Backend/Tasks.php?userId=${id}`)
            .then(response => response.json())
            .then(data => {
                const filteredTasks = data.tasks?.filter(task => {
                    if (permission === 'admin') {
                        return statusFilter === 'inProgress' ? task.status !== 1 : task.status === 1;
                    } else {
                        return task.status !== 1;
                    }
                }) || [];
                setTasks(filteredTasks);
            })
            .catch(error => console.error('Error fetching tasks:', error));
    };

    const fetchUsers = () => {
        if (permission === 'admin') {
            fetch('https://test.logixmedia.ch/apprentihub/src/ApprentiHub/Backend/Users.php')
                .then(response => response.json())
                .then(data => {
                    const nonAdminUsers = data.filter(user => user.permission !== 'admin'); 
                    setUsers(nonAdminUsers);
                    if (nonAdminUsers.length > 0) {
                        setCurrentUserIndex(0); 
                        fetchTasks(nonAdminUsers[0].id); 
                    }
                })
                .catch(error => console.error('Error fetching users:', error));
        } else {
            fetchTasks(userId);
        }
    };
    
    useEffect(() => {
        fetchUsers();
    }, [userId, permission]);

    useEffect(() => {
        if (permission === 'admin' && users.length > 0) {
            fetchTasks(users[currentUserIndex].id); 
        } else {
            fetchTasks(userId);
        }
    }, [currentUserIndex, users, permission, statusFilter]);

    const handleBoxClick = (task) => {
        if (permission === 'admin') {
            if (selectedTasks.includes(task.id)) {
                setSelectedTasks(selectedTasks.filter(id => id !== task.id));
            } else {
                setSelectedTasks([...selectedTasks, task.id]);
            }
        }
    };

    const handleViewMore = (event, task) => {
        event.stopPropagation(); 
        setSelectedTask(task);
        setIsNewTask(false);  
        setIsDialogOpen(true);
    };

    const handleDeleteSelectedTasks = () => {
        if (selectedTasks.length > 0) {
            fetch('https://test.logixmedia.ch/apprentihub/src/ApprentiHub/Backend/Tasks.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ taskIds: selectedTasks }),
            })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    setSelectedTasks([]);
                    fetchTasks(permission === 'admin' ? users[currentUserIndex].id : userId);
                } else {
                    console.error('Error deleting tasks:', data.message);
                }
            })
            .catch(error => console.error('Error deleting tasks:', error));
        }
    };    

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
        setSelectedTask(null);
    };

    const handleTaskCompleted = () => {
        setIsDialogOpen(false);
        setSelectedTask(null);
        fetchTasks(permission === 'admin' ? users[currentUserIndex].id : userId);
    };

    const handleCreateTask = () => {
        setSelectedTask({ title: '', description: '', priority: 1 }); 
        setIsNewTask(true);
        setIsDialogOpen(true);
    };

    const handlePreviousUser = () => {
        setCurrentUserIndex((prevIndex) => 
            prevIndex === 0 ? users.length - 1 : prevIndex - 1
        );
    };

    const handleNextUser = () => {
        setCurrentUserIndex((prevIndex) => 
            prevIndex === users.length - 1 ? 0 : prevIndex + 1
        );
    };

    const handleStatusFilterChange = (filter) => {
        setStatusFilter(filter);
    };

    return (
        <div className='background-tasks'>
            <div className='container-filter'>
                {permission === 'admin' && ( // Filtrer l'affichage pour les administrateurs uniquement
                    <div className='filter-status'>
                        <span 
                            className={statusFilter === 'inProgress' ? 'active' : ''}
                            onClick={() => handleStatusFilterChange('inProgress')}
                        >
                            En cours
                        </span>
                        <span 
                            className={statusFilter === 'closed' ? 'active' : ''}
                            onClick={() => handleStatusFilterChange('closed')}
                        >
                            Terminée
                        </span>
                    </div>
                )}
                {permission === 'admin' && users.length > 0 && (
                    <div className='filter-name'>
                        <FontAwesomeIcon icon={faChevronLeft} className='arrow' onClick={handlePreviousUser} />
                        <span>{users[currentUserIndex]?.firstname} {users[currentUserIndex]?.lastname}</span>
                        <FontAwesomeIcon icon={faChevronRight} className='arrow' onClick={handleNextUser} />
                    </div>
                )}
            </div>
            <div className='container-box'>
                {Array.isArray(tasks) && tasks.length > 0 ? (
                    tasks.map((task, index) => {
                        let isGroupedTask = false;
                    
                        try {
                            if (task.hasOwnProperty('assigned')) {  
                                const assignedUsers = JSON.parse(task.assigned);
                                if (Array.isArray(assignedUsers) && assignedUsers.length > 1) {
                                    isGroupedTask = true;
                                }
                            }
                        } catch (error) {
                            console.error("Erreur lors de l'analyse du champ assigned :", error);
                        }
                    
                        return (
                            <div
                                key={index}
                                className={`box ${selectedTasks.includes(task.id) ? 'selected' : ''}`}
                                onClick={() => handleBoxClick(task)}
                            >
                                <h1>{task.title}</h1>
                                <div className='container-info'>
                                    <span>
                                        <FontAwesomeIcon icon={isGroupedTask ? faUserGroup : faUser} />
                                    </span>
                                    <span>{task.priority}</span>
                                    <span
                                        className={task.status === 1 ? 'btn-closed' : 'btn-in-progress'}
                                    >
                                        {task.status === 1 ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faClockRotateLeft} />}
                                    </span>
                                </div>
                                <div className='container-btn'>
                                    <button onClick={(event) => handleViewMore(event, task)}>
                                        <FontAwesomeIcon className='icon' icon={faMagnifyingGlass} />
                                        <p>Détails</p>
                                    </button>
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <h3>Aucune tâche à afficher.</h3>
                )}
            </div>
            <div className='footer-tools'>
                <div className='container-tools'>
                    <button onClick={handleCreateTask}><FontAwesomeIcon icon={faPlus} /></button> 
                    {permission === 'admin' && selectedTasks.length > 0 && (
                        <button
                            onClick={handleDeleteSelectedTasks}
                            className={selectedTasks.length > 0 ? 'active' : ''}
                        >
                            <FontAwesomeIcon icon={faTrash} />
                        </button>
                    )}
                </div>
            </div>

            {isDialogOpen && (
                <DialogTasks 
                    task={selectedTask} 
                    onClose={handleCloseDialog} 
                    onTaskCompleted={handleTaskCompleted}  
                    isNewTask={isNewTask} 
                />
            )}
        </div>
    );
}

export default Tasks;
