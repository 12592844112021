import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './Header.css';

function Header() {
    const location = useLocation();
    const navigate = useNavigate();

    const handleLogoClick = () => {
        if (location.pathname === '/discussion' || location.pathname === '/tache' || location.pathname === '/gestion') {
            navigate('/accueil');
        }
    };

    if (location.pathname === '/discussion') {
        return null;
    }

    return (
        <header>
            <ul>
                <span className='container-logo' onClick={handleLogoClick}>
                    {location.pathname === '/tache' || location.pathname === '/discussion' || location.pathname === '/gestion' ? (
                        <span className='btn-back'>Retourner à l'accueil</span>
                    ) : (
                        <h1>Logo Médiamatique</h1>
                    )}
                </span>
            </ul>
        </header>
    );
}

export default Header;
