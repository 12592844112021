import React from 'react';
import './Home.css';
import { useAuth } from '../Services/AuthContext';
import { useNavigate } from 'react-router-dom';

function Home() {
    const { logout, permission } = useAuth(); 
    const navigate = useNavigate();

    const handleLogout = () => {
        logout();
    };

    const handleTachesClick = () => {
        navigate('/tache');
    };

    const handleChatClick = () => {
        navigate('/discussion');
    };

    const handleManagementClick = () => {
        navigate('/gestion');
    };

    return (
        <div className='container-box-home'>
            <div className='top-row'>
                <div
                    className='hexagon horaire'
                    onClick={permission === 'admin' ? handleManagementClick : undefined}
                >
                    <span>{permission === 'admin' ? 'Gestion' : 'Horaire'}</span>
                </div>
                <div className='hexagon rapport'><span>Rapport</span></div>
            </div>
            <div className='center-row'>
                <div className='hexagon discussion' onClick={handleChatClick}><span>Discussion</span></div>
                <div className='hexagon taches' onClick={handleTachesClick}><span>Tâches</span></div>
                <div className='hexagon actualite'><span>Actualité</span></div>
            </div>
            <div className='bottom-row'>
                <div className='hexagon profil'><span>Paramètre</span></div>
                <div className='hexagon deconnexion' onClick={handleLogout}><span>Déconnexion</span></div>
            </div>
        </div>
    );
}

export default Home;
