import React, { createContext, useState, useContext, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [userId, setUserId] = useState(null);
    const [permission, setPermission] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const storedAuth = localStorage.getItem('isAuthenticated');
        const storedUserId = localStorage.getItem('userId');
        const storedPermission = localStorage.getItem('permission');
        if (storedAuth === 'true' && storedUserId) {
            setIsAuthenticated(true);
            setUserId(parseInt(storedUserId));
            setPermission(storedPermission); 
        }
        setLoading(false); 
    }, []);

    const login = (id, userPermission) => { 
        setIsAuthenticated(true);
        setUserId(id);
        setPermission(userPermission); 
        localStorage.setItem('isAuthenticated', 'true');
        localStorage.setItem('userId', id);
        localStorage.setItem('permission', userPermission); 
    };

    const logout = () => {
        setIsAuthenticated(false);
        setUserId(null);
        setPermission(null); 
        localStorage.removeItem('isAuthenticated');
        localStorage.removeItem('userId');
        localStorage.removeItem('permission'); 
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, loading, userId, permission, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
