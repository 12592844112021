import React, { useState, useEffect } from 'react';
import './DialogManagement.css';

function DialogManagement({ mode, user, onClose }) {
    const [isClosing, setIsClosing] = useState(false);
    const [formData, setFormData] = useState({
        lastname: '',
        forename: '',
        email: '',
        sex: 'male',
        permission: 'user'
    });

    useEffect(() => {
        if (mode === 'edit' && user) {
            setFormData({
                lastname: user.lastname,
                forename: user.firstname,
                email: user.mail,
                sex: user.sex,
                permission: user.permission
            });
        } else {
            setFormData({
                lastname: '',
                forename: '',
                email: '',
                sex: 'male',
                permission: 'user'
            });
        }
    }, [mode, user]);

    const handleClose = () => {
        setIsClosing(true);
        setTimeout(() => {
            setIsClosing(false);
            onClose();
        }, 250);
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async () => {
        try {
            const response = await fetch('https://test.logixmedia.ch/apprentihub/src/ApprentiHub/Backend/Users.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    ...formData,
                    id: mode === 'edit' ? user.id : undefined,  
                    action: mode === 'edit' ? 'update' : 'create'
                }),
            });

            if (response.ok) {
                handleClose(); 
            } else {
                console.error('Erreur lors de la sauvegarde de l\'utilisateur');
            }
        } catch (error) {
            console.error('Erreur réseau:', error);
        }
    };

    const handleResetPassword = async () => {
        try {
            const response = await fetch('https://test.logixmedia.ch/apprentihub/src/ApprentiHub/Backend/Users.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: user.id, 
                    action: 'reset_password'
                }),
            });

            if (response.ok) {
                alert('Mot de passe réinitialisé avec succès');
            } else {
                console.error('Erreur lors de la réinitialisation du mot de passe');
            }
        } catch (error) {
            console.error('Erreur réseau:', error);
        }
    };

    return (
        <div className={`dialogmanagement ${isClosing ? 'closing' : ''}`}>
            <h1>{mode === 'edit' ? 'Modification d\'utilisateur' : 'Création d\'un utilisateur'}</h1>
            <div className='container-name'>
                <div className='input-name'>
                    <label htmlFor="lastname">Nom</label>
                    <input 
                        type="text" 
                        id="lastname" 
                        name="lastname" 
                        value={formData.lastname} 
                        onChange={handleChange} 
                    />
                </div>
                <div className='input-name'>
                    <label htmlFor="forename">Prénom</label>
                    <input 
                        type="text" 
                        id="forename" 
                        name="forename" 
                        value={formData.forename} 
                        onChange={handleChange} 
                    />
                </div>
            </div>
            <div className='input-box'>
                <label htmlFor="email">E-mail</label>
                <input 
                    type="email" 
                    id="email" 
                    name="email" 
                    value={formData.email} 
                    onChange={handleChange} 
                />
            </div>
            <div className='input-box'>
                <label htmlFor="sex">Sexe</label>
                <select id="sex" name="sex" value={formData.sex} onChange={handleChange}>
                    <option value="male">Homme</option>
                    <option value="female">Femme</option>
                </select>
            </div>
            <div className='input-box'>
                <label htmlFor="permission">Permission</label>
                <select id="permission" name="permission" value={formData.permission} onChange={handleChange}>
                    <option value="user">Utilisateur</option>
                    <option value="admin">Administrateur</option>
                </select>
            </div>

            {mode === 'edit' && (
                <div className='container-reset-password'>
                    <button onClick={handleResetPassword} className='reset-password-btn'>Réinitialiser le mot de passe</button>
                </div>
            )}

            <div className='container-btn'>
                <button onClick={handleClose}>Annuler</button>
                <button onClick={handleSubmit}>{mode === 'edit' ? 'Enregistrer' : 'Créer l\'utilisateur'}</button>
            </div>
        </div>
    );
}

export default DialogManagement;
