import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import DialogManagement from './DialogManagement/DialogManagement';  
import './Management.css';
import Avatar_1 from '../Images/Avatar_1.png';
import Avatar_2 from '../Images/Avatar_2.png';

function Management() {
    const [users, setUsers] = useState([]);
    const [selectedUserIds, setSelectedUserIds] = useState([]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [dialogMode, setDialogMode] = useState('create');  
    const [selectedUser, setSelectedUser] = useState(null);

    const fetchUsers = () => {
        fetch('https://test.logixmedia.ch/apprentihub/src/ApprentiHub/Backend/Users.php')
            .then(response => response.json())
            .then(data => setUsers(data))
            .catch(error => console.error('Error fetching users:', error));
    };

    useEffect(() => {
        fetchUsers();

        const intervalId = setInterval(fetchUsers, 500);

        return () => clearInterval(intervalId);
    }, []);

    const handleBoxClick = (userId) => {
        if (selectedUserIds.includes(userId)) {
            setSelectedUserIds(selectedUserIds.filter(id => id !== userId)); 
        } else {
            setSelectedUserIds([userId]); 
        }
    };

    const deleteUsers = (userIds) => {
        fetch(`https://test.logixmedia.ch/apprentihub/src/ApprentiHub/Backend/Users.php`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ids: userIds, action: 'delete' }),
        })
        .then(response => response.json())
        .then(data => {
            if (data.status === 'success') {
                setUsers(users.filter(user => !userIds.includes(user.id)));
                setSelectedUserIds([]);
            } else {
                console.error('Error deleting users:', data.message);
            }
        })
        .catch(error => console.error('Network error:', error));
    };

    const handleDeleteClick = () => {
        if (selectedUserIds.length > 0) {
            deleteUsers(selectedUserIds);
        }
    };

    const getPermissionLabel = (permission) => {
        return permission === 'admin' ? 'Administrateur' : 'Utilisateur';
    };

    const openDialog = (mode) => {
        if (mode === 'edit' && selectedUserIds.length === 1) {
            const userToEdit = users.find(user => user.id === selectedUserIds[0]);
            setSelectedUser(userToEdit);
        } else {
            setSelectedUser(null);
        }
        setDialogMode(mode);
        setIsDialogOpen(true);
    };

    const closeDialog = () => {
        setIsDialogOpen(false);
    };

    return (
        <div className='background-management'>
            <div className='user-list'>
                <h1>Gestion des utilisateurs</h1>
                <div className='container-box'>
                    {users.map(user => (
                        <div 
                            key={user.id} 
                            className={`box ${selectedUserIds.includes(user.id) ? 'selected' : ''}`}
                            onClick={() => handleBoxClick(user.id)}
                        >
                            <div className='container-avatar'>
                                <img src={user.sex === 'female' ? Avatar_2 : Avatar_1} alt={`${user.firstname} ${user.lastname}'s avatar`} />
                            </div>
                            <div className='container-info'>
                                <h2>{user.firstname} {user.lastname}</h2>
                                <p>{getPermissionLabel(user.permission)}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            
            <div className='footer-tools'>
                <div className='container-tools'>
                    <button onClick={() => openDialog('create')}><FontAwesomeIcon icon={faPlus} /></button>
                    {selectedUserIds.length === 1 && (
                        <button onClick={() => openDialog('edit')}><FontAwesomeIcon icon={faPen} /></button>
                    )}
                    {selectedUserIds.length > 0 && (
                        <button onClick={handleDeleteClick}><FontAwesomeIcon icon={faTrash} /></button>
                    )}
                </div>
            </div>

            {isDialogOpen && (
                <div className={`background-dialogmanagement ${isDialogOpen ? 'open' : ''}`}>
                    <DialogManagement 
                        mode={dialogMode} 
                        user={selectedUser} 
                        onClose={closeDialog} 
                    />
                </div>
            )}
        </div>
    );
}

export default Management;
