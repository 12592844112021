import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './ApprentiHub/Home/Home';
import Management from './ApprentiHub/Management/Management';
import Tasks from './ApprentiHub/Tasks/Tasks';
import Connection from './ApprentiHub/Connection/Connection';
import Chat from './ApprentiHub/Chat/Chat';
import PrivateRoute from './ApprentiHub/Services/PrivateRoute';
import { AuthProvider } from './ApprentiHub/Services/AuthContext';
import NoMatch from './ApprentiHub/Services/NoMatch';
import Header from './ApprentiHub/Header/Header';

const App = () => {
    return (
        <AuthProvider>
            <Router>
                <Header />
                <Routes>
                    <Route path="/" element={<Connection />} />
                    <Route path="/accueil" element={<PrivateRoute element={<Home />} />} />
                    <Route path="/tache" element={<PrivateRoute element={<Tasks />} />} />
                    <Route path="/discussion" element={<PrivateRoute element={<Chat />} />} />
                    <Route path="/gestion" element={<PrivateRoute element={<Management />} requiredPermission="admin" />} />
                    <Route path="*" element={<NoMatch />} />
                </Routes>
            </Router>
        </AuthProvider>
    );
};

export default App;
