import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

const PrivateRoute = ({ element, requiredPermission }) => {
    const { isAuthenticated, loading, permission } = useAuth();

    if (loading) {
        return <div>Chargement...</div>; 
    }

    if (!isAuthenticated) {
        return <Navigate to="/" />;
    }

    if (requiredPermission && permission !== requiredPermission) {
        return <Navigate to="/accueil" />;
    }

    return element;
};

export default PrivateRoute;
