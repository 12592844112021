import React, { useState, useEffect } from 'react';
import './DialogTasks.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCheck } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../Services/AuthContext';
import Alert from '../../Alert/Alert';

function Tag({ label, onRemove }) {
    return (
        <div className="tag">
            {label}
            <span onClick={onRemove} className="tag-close">&times;</span>
        </div>
    );
}

function SubTask({ subTask, onToggleComplete, onRemove, isNewTask, taskStatus }) {
    const truncateTitle = (title, maxLength) => {
        if (title.length > maxLength) {
            return title.substring(0, maxLength) + "...";
        }
        return title;
    };

    const isTaskClosed = taskStatus === 1; // Tâche clôturée

    return (
        <div
            className="sub-task"
            style={subTask.status === 1 && !isNewTask ? { textDecoration: 'line-through', color: '#c5c5c5' } : {}}
        >
            <div className="sub-task-title">
                {truncateTitle(subTask.title, 25)}
                {isNewTask ? (
                    <span onClick={onRemove} className="sub-task-close">&times;</span>
                ) : (
                    <span
                        onClick={() => {
                            if (!isTaskClosed || subTask.status === 0) {
                                onToggleComplete();
                            }
                        }}
                        className="sub-task-close"
                        style={isTaskClosed && subTask.status === 1 ? { cursor: 'not-allowed' } : {}}
                    >
                        <FontAwesomeIcon icon={faCheck} style={subTask.status === 1 ? { color: '#c5c5c5' } : {}} />
                    </span>
                )}
            </div>
        </div>
    );
}


function DialogTasks({ task, onClose, onTaskCompleted, isNewTask = false }) {
    const { permission } = useAuth();
    const [isClosing, setIsClosing] = useState(false);
    const [alertMessage, setAlertMessage] = useState({ message: '', type: '' });
    const [taskData, setTaskData] = useState({
        title: task?.title || '',
        description: isNewTask ? '' : (task?.description || 'Aucune information fournie.'),
        priority: task?.priority || 1,
        assigned: [],
    });
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState("");
    const [taskType, setTaskType] = useState('individual');
    const [subTasks, setSubTasks] = useState([]);
    const [newSubTaskTitle, setNewSubTaskTitle] = useState('');

    const priorityDescriptions = {
        1: '1 - Pas urgent',
        2: '2 - Peu urgent',
        3: '3 - Moyennement urgent',
        4: '4 - Très urgent',
        5: '5 - Extrêmement urgent',
    };

    useEffect(() => {
        if (permission === 'admin' && isNewTask) {
            fetchUsers();
        }
    
        if (!isNewTask && task) {
            fetchSubTasks(task.id);
        }
    }, [permission, isNewTask, task]);

    const fetchUsers = () => {
        fetch('https://test.logixmedia.ch/apprentihub/src/ApprentiHub/Backend/Users.php')
            .then(response => response.json())
            .then(data => {
                const nonAdminUsers = data.filter(user => user.permission !== 'admin');
                setUsers(nonAdminUsers);
            })
            .catch(error => console.error('Erreur lors de la récupération des utilisateurs:', error));
    };
    
    const fetchSubTasks = (taskId) => {
        fetch(`https://test.logixmedia.ch/apprentihub/src/ApprentiHub/Backend/Tasks.php?task_id=${taskId}`)
            .then(response => response.json())
            .then(data => {
                setSubTasks(data);
            })
            .catch(error => console.error('Erreur lors de la récupération des sous-tâches:', error));
    };
    
    const handleClose = () => {
        setIsClosing(true);
        setTimeout(() => {
            onClose();
            setIsClosing(false);
        }, 250);
    };

    const areAllSubTasksCompleted = () => {
        return subTasks.every(subTask => subTask.status === 1);
    };

    const handleSaveTask = () => {
        if (taskData.title.trim() === '') {
            setAlertMessage({ message: 'Le titre ne peut pas être vide.', type: 'error' });
            return;
        }

        if (isNewTask && taskData.assigned.length === 0) {
            setAlertMessage({ message: 'Vous devez sélectionner au moins un utilisateur pour la tâche.', type: 'error' });
            return;
        }

        const checkTitleExists = async () => {
            const response = await fetch('https://test.logixmedia.ch/apprentihub/src/ApprentiHub/Backend/Tasks.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ checkTitle: true, title: taskData.title }),
            });

            const data = await response.json();
            return data.exists;
        };

        if (isNewTask) {
            checkTitleExists().then((exists) => {
                if (exists) {
                    setAlertMessage({ message: 'Une tâche avec ce titre existe déjà. Veuillez en sélectionner un nouveau.', type: 'error' });
                } else {
                    saveTask();
                }
            });
        } else {
            saveTask();
        }
    };

    const saveTask = () => {
        const url = 'https://test.logixmedia.ch/apprentihub/src/ApprentiHub/Backend/Tasks.php';
    
        const requestBody = isNewTask
            ? {
                ...taskData,
                isNewTask: true,
                taskType: taskType,
                assigned: taskData.assigned,
                subTasks: subTasks  
            } : {
                id: task.id
            };
    
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    onTaskCompleted();
                } else {
                    setAlertMessage({ message: 'Échec de la sauvegarde de la tâche.', type: 'error' });
                }
            })
            .catch(error => {
                setAlertMessage({ message: 'Erreur lors de la sauvegarde de la tâche.', type: 'error' });
                console.error(error);
            });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setTaskData({ ...taskData, [name]: value });
    };

    const handleUserSelect = (e) => {
        const selectedUserId = e.target.value;
        if (selectedUserId && !taskData.assigned.includes(selectedUserId)) {
            setTaskData(prevState => ({
                ...prevState,
                assigned: [...prevState.assigned, selectedUserId]
            }));
            setSelectedUser("");
        }
    };

    const handleRemoveTag = (userId) => {
        setTaskData(prevState => ({
            ...prevState,
            assigned: prevState.assigned.filter(id => id !== userId)
        }));
    };

    const handleTaskTypeChange = (e) => {
        setTaskType(e.target.value);
    };

    const handleAddSubTask = () => {
        if (newSubTaskTitle.trim() !== '') {
            const subTaskExists = subTasks.some(subTask => subTask.title.toLowerCase() === newSubTaskTitle.toLowerCase());
            if (subTaskExists) {
                setAlertMessage({ message: 'Une sous-tâche avec ce nom existe déjà.', type: 'error' });
            } else {
                setSubTasks([...subTasks, { title: newSubTaskTitle, description: '', status: 0 }]);
                setNewSubTaskTitle('');
            }
        }
    };

    const handleToggleSubTaskComplete = (index) => {
        const subTask = subTasks[index];
        const newStatus = subTask.status === 1 ? 0 : 1;
    
        fetch('https://test.logixmedia.ch/apprentihub/src/ApprentiHub/Backend/Tasks.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ subTaskId: subTask.id, status: newStatus }),
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                setSubTasks((prevSubTasks) =>
                    prevSubTasks.map((task, i) =>
                        i === index ? { ...task, status: newStatus } : task
                    )
                );
            } else {
                setAlertMessage({ message: 'Échec de la mise à jour du statut de la sous-tâche.', type: 'error' });
            }
        })
        .catch(error => {
            setAlertMessage({ message: 'Erreur lors de la mise à jour du statut de la sous-tâche.', type: 'error' });
            console.error(error);
        });
    };
    
    const handleRemoveSubTask = (index) => {
        const updatedSubTasks = subTasks.filter((_, i) => i !== index);
        setSubTasks(updatedSubTasks);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);

        const daysOfWeek = [
            'Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'
        ];
        const monthsOfYear = [
            'janvier', 'février', 'mars', 'avril', 'mai', 'juin',
            'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'
        ];

        const dayOfWeek = daysOfWeek[date.getDay()];
        const day = date.getDate();
        const month = monthsOfYear[date.getMonth()];
        const year = date.getFullYear();
        const hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, '0');

        return `${dayOfWeek} ${day} ${month} ${year} à ${hours}h${minutes}`;
    };

    useEffect(() => {
        if (alertMessage.message) {
            const timer = setTimeout(() => {
                setAlertMessage({ message: '', type: '' });
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [alertMessage]);

    return (
        <div className={`background-dialogtasks ${task ? (isClosing ? 'closing' : 'open') : ''}`}>
            <div className='dialogtasks'>
                <h1>{isNewTask ? 'Créer une nouvelle tâche' : 'Détails de la tâche'}</h1>
                {alertMessage.message && (
                    <Alert message={alertMessage.message} type={alertMessage.type} onClose={() => setAlertMessage({ message: '', type: '' })} />
                )}
                <div className='container-input-1'>
                    <div className='input-box'>
                        <label htmlFor="task-title">Titre</label>
                        <input
                            type="text"
                            id="task-title"
                            name="title"
                            value={taskData.title}
                            onChange={handleChange}
                            readOnly={!isNewTask}
                            maxLength={85}
                        />
                    </div>
                    <div className='input-box'>
                        <label htmlFor="task-priority">Priorité de la tâche</label>
                        {isNewTask ? (
                            <select
                                id="task-priority"
                                name="priority"
                                value={taskData.priority}
                                onChange={handleChange}
                            >
                                <option value={1}>1 - Pas urgent</option>
                                <option value={2}>2 - Peu urgent</option>
                                <option value={3}>3 - Moyennement urgent</option>
                                <option value={4}>4 - Très urgent</option>
                                <option value={5}>5 - Extrêmement urgent</option>
                            </select>
                        ) : (
                            <input
                                type="text"
                                id="task-priority"
                                name="priority"
                                value={priorityDescriptions[taskData.priority]}
                                readOnly
                            />
                        )}
                    </div>
                </div>
                {isNewTask && permission === 'admin' && (
                    <>
                        <div className='container-input-2'>
                            <div className='input-box'>
                                <label htmlFor="task-assigned">Attribution de la tâche</label>
                                <>
                                    <select
                                        id="task-assigned"
                                        name="assigned"
                                        value={selectedUser}
                                        onChange={handleUserSelect}
                                    >
                                        <option value="">Sélectionnez un utilisateur</option>
                                        {users.map(user => (
                                            <option key={user.id} value={user.id}>
                                                {user.firstname} {user.lastname}
                                            </option>
                                        ))}
                                    </select>
                                    <div className="tag-container">
                                        {taskData.assigned.map(userId => {
                                            const user = users.find(user => user.id === Number(userId));
                                            if (!user) return null;
                                            return (
                                                <Tag
                                                    key={userId}
                                                    label={`${user.firstname} ${user.lastname}`}
                                                    onRemove={() => handleRemoveTag(userId)}
                                                />
                                            );
                                        })}
                                    </div>
                                </>
                            </div>
                            <div className='input-box'>
                                <label htmlFor="task-type">Type de tâche</label>
                                <select
                                    id="task-type"
                                    name="taskType"
                                    value={taskType}
                                    onChange={handleTaskTypeChange}
                                >
                                    <option value="individual">Tâche personnelle</option>
                                    <option value="grouped">Tâche de groupe</option>
                                </select>
                            </div>
                        </div>
                    </>
                )}
                <div className="container-input-3">
                    {!isNewTask && (
                        <div className="sub-task-list">
                            <label>Sous-tâches</label>
                            {subTasks.length > 0 ? (
                                subTasks.map((subTask, index) => (
                                    <SubTask
                                        key={index}
                                        subTask={subTask}
                                        onToggleComplete={() => handleToggleSubTaskComplete(index)}
                                        isNewTask={false}
                                        taskStatus={task.status} 
                                    />
                                ))
                            ) : (
                                <p>Pas de sous-tâche disponible actuellement.</p>
                            )}
                        </div>
                    )}
                    {isNewTask && (
                        <>
                            <label htmlFor="subtask-title">Créer une sous-tâche</label>
                            <div className="sub-task-input">
                                <input
                                    type="text"
                                    id="subtask-title"
                                    name="subtask-title"
                                    value={newSubTaskTitle}
                                    onChange={(e) => setNewSubTaskTitle(e.target.value)}
                                    placeholder="Nom de la sous-tâche"
                                    maxLength={75}
                                />
                                <button onClick={handleAddSubTask} className="add-subtask-btn">
                                    <FontAwesomeIcon icon={faPlus} />
                                </button>
                            </div>
                            <div className="sub-task-list">
                                {subTasks.map((subTask, index) => (
                                    <SubTask
                                        key={index}
                                        subTask={subTask}
                                        onRemove={() => handleRemoveSubTask(index)}
                                        isNewTask={true}
                                    />
                                ))}
                            </div>
                        </>
                    )}
                </div>

                <div className='container-input-4'>
                    <label htmlFor="task-description">Notes</label>
                    <textarea
                        name="description"
                        id="task-description"
                        value={taskData.description}
                        onChange={handleChange}
                        readOnly={!isNewTask}
                    />
                </div>
                {!isNewTask && (
                    <div className='input-box'>
                        <label htmlFor="task-created">La tâche a été créée</label>
                        <input
                            type="text"
                            id="task-created"
                            value={formatDate(task.created)}
                            readOnly
                        />
                    </div>
                )}
                <div className='container-btn'>
                    <button onClick={handleClose}>{isNewTask ? 'Annuler' : 'Fermer'}</button>
                    {isNewTask ? (
                        <button onClick={handleSaveTask}>Créer la tâche</button>
                    ) : (
                        task.status !== 1 && (
                            <button 
                                onClick={handleSaveTask} 
                                disabled={!areAllSubTasksCompleted()} 
                                className={!areAllSubTasksCompleted() ? 'disabled-button' : ''}
                            >
                                Tâche terminée
                            </button>
                        )
                    )}
                </div>

            </div>
        </div>
    );
}

export default DialogTasks;
